<template>
    <div
        class="Column"
        v-bind="item.props"
        v-tooltip="item.props && item.props.tooltip ? item.props.tooltip : null"
        @click="e => handleClick(e, item)"
    >
        <span v-if="item.name && (index === -1 || (hasHeader && index === 0))" class="Name">{{
            item.name
        }}</span>
        <component v-if="item" :is="item.component || el" class="Value" v-bind="item.props">
            <span v-html="item.value" />
        </component>
        <slot></slot>
    </div>
</template>

<script>
import Checkbox from '@/components/widgets/Checkbox';
import Button from '@/components/widgets/Button';
import Datepicker from '@/components/widgets/Datepicker';
import Download from '@/components/widgets/Download';

export default {
    components: {
        Checkbox,
        Button,
        Datepicker,
        Download,
    },
    props: {
        hasHeader: Boolean,
        childIndex: Number,
        index: Number,
        isSmall: Boolean,
        item: {
            name: {
                type: String,
                default: null,
            },
            value: {
                type: String,
                default: null,
            },
        },
        el: {
            type: [String, Object],
            default: 'span',
        },
    },

    methods: {
        handleClick(e, item) {
            if (item?.props?.onClick) {
                e.stopPropagation();
                item.props.onClick(item);
            }
            this.$emit('onClick', item);
        },
    },

    mounted() {
        if (this.item?.props?.onClick && this.$el.querySelector('button') === null) {
            this.$el.classList.add('hasClick');
        }
    },
};
</script>

<style lang="scss">
.Column {
    display: flex;
    justify-content: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 8px 3px;
    flex: 1;
    font-family: inherit;

    &.hasClick {
        cursor: pointer;
        margin: 4px 0;
        text-decoration: underline;
        border-radius: 4px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.15);
        }
    }

    &.isSmall {
        .Name {
            font-size: 0.65rem;
            color: var(--color-text-gray-dark);
            overflow: hidden;

            &.is-hidden {
                max-height: 0;
                transition: max-height 0.195s 0.2s;
            }
        }

        .Value {
            font-size: 0.95rem;
            color: rgba(0, 0, 0, 0.65);
        }
    }

    .Name {
        font-size: 0.6625rem;
        color: var(--color-text-blue);
        text-transform: uppercase;
        line-height: 1.25;
        margin-bottom: 4px;
    }

    .Value {
        font-size: 12px;
        line-height: 1.25;
        color: var(--color-text-blue);
        font-weight: 700;
        margin: 0;
        user-select: all;

        > a {
            color: var(--color-text-blue);
            text-decoration: underline;
            font-size: 1rem;
        }
    }
}
</style>
