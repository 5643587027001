<template>
    <div class="Checkbox" :class="[{ 'Checkbox--hasError': hasError }, `Checkbox--${size}`]">
        <input
            type="checkbox"
            :id="name || value"
            :name="name"
            :disabled="isDisabled"
            :checked="value"
            @click="handleClick"
        />
        <label :for="name"><slot /></label>
    </div>
</template>
<script>
export default {
    props: {
        size: {
            type: String,
            default: 'medium',
        },
        name: String,
        hasError: Boolean,
        isDisabled: Boolean,
        onCheck: {
            type: Function,
            default: () => ({}),
        },
        value: Boolean,
    },
    data() {
        return {
            isChecked: this.value,
        };
    },
    methods: {
        handleClick(e) {
            this.isChecked = !this.isChecked;
            this.$emit('onCheck', this.isChecked);
            this.onCheck(this.isChecked);
        },
    },
};
</script>

<style lang="scss" scoped>
.Checkbox {
    display: flex;
    margin: 10px 0;

    &.Checkbox--hasError {
        color: color(red);
    }

    &.Checkbox--small {
        display: flex;
        align-items: center;
        margin: 0;

        a,
        label {
            margin: 0;
            font-size: 12px;
        }

        input {
            margin: 0;
            margin-right: 5px;
            width: 15px;
            height: 15px;
        }
    }

    input {
        margin: 0;
        margin-right: 10px;
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    input[type='checkbox'] {
        border-radius: 0px;
    }

    label,
    a {
        font-size: 18px;
        line-height: 1.5;
        user-select: none;
        margin-top: -4px;
        color: inherit;
        flex: 1;
        cursor: pointer;
    }

    .Checkbox-Description {
        font-size: 12px;
        line-height: 1rem;
        display: block;
        color: color(gray-dark);
    }
}
</style>
