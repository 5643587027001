<template>
    <div class="WorkingTime" :class="{ 'is-loading': isFetchingData }">
        <Headline title="Übersicht der" highlight="Arbeitszeiten"></Headline>
        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    placeholder="Fahrer"
                    :items="drivers.filter(d => d.isVisible)"
                    :selected="selectedEmployee"
                    @onItemSelect="handleDriverChange"
                />
                <Datepicker monthPicker :startDate="selectedFrom" @onChange="handleDateChange" />
                <Button @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
            </div>
            <div>
                <Download
                    v-tooltip="!this.selectedEmployee ? 'Wählen Sie einen Fahrer aus' : ''"
                    :disabled="!this.selectedEmployee"
                    title="Download PDF"
                    type="pdf"
                    @onDownload="handleDownload"
                />
            </div>
        </Toolbar>
        <employee-working-time-table
            v-if="employeeWorkTime.length && selectedEmployee"
            :workingTimes="this.employeeWorkTime"
            @onClick="handleEmployeeRowClick"
        />
        <working-time-table
            v-else-if="workingTimes.length"
            :workingTimes="this.workingTimes"
            @onClick="handleRowClick"
        />
        <div v-else-if="isFetchingData" class="Spinner-Wrap"><spinner /></div>
        <EmptyState v-else />
        <Modal
            title="Markieren Sie die Pausen"
            :show="pauseModalIsActive"
            :action="{ text: 'Speichern', color: 'green', callback: handleSavePauses }"
            :cancel="{ text: 'Abbrechen' }"
            :size="'medium'"
            :isLoading="isFetchingData"
            @onModalClose="handleModalClose"
        >
            <row
                v-for="(pause, i) in currentPauses"
                :key="i"
                :items="pause"
                :index="-1"
                variant="minimal"
            />
        </Modal>
    </div>
</template>
<script type="text/javascript">
import axios from 'axios';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { getDuration } from '@/lib/helper';

import Datepicker from '@/components/widgets/Datepicker';
import Download from '@/components/widgets/Download';
import Dropdown from '@/components/widgets/Dropdown';
import Modal from '@/components/widgets/Modal';
import Button from '@/components/widgets/Button';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import WorkingTimeTable from './components/WorkingTimeTable.vue';
import EmployeeWorkingTimeTable from './components/EmployeeWorkingTimeTable.vue';

import Spinner from '@/components/widgets/Spinner.vue';
import Row from '@/components/Row.vue';
import EmptyState from '@/components/EmptyState.vue';

export default {
    name: 'AppNavigation',
    components: {
        Modal,
        Datepicker,
        Download,
        Dropdown,
        Headline,
        Toolbar,
        WorkingTimeTable,
        Spinner,
        Button,
        Row,
        EmployeeWorkingTimeTable,
        EmptyState,
    },

    data: () => {
        return {
            pauseModalIsActive: false,
            isFetchingData: false,
            employeeWorkTime: [],
            workingTimes: [],
            currentPauses: [],
            changedPauses: [],
            drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
            selectedEmployee: null,
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfMonth(new Date()),
        };
    },
    methods: {
        handleRandomName() {
            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 2)];
        },
        handleEmployeeRowClick({ index }) {},
        handleRowClick(driverNumber) {
            console.log('driverNumber', driverNumber);
            const workTime = this.workingTimes.find(wt => wt.driverNumber === driverNumber);
            console.log('workTime', workTime);
            const hasPauses = workTime.pause.length;
            console.log('hasPauses', hasPauses);
            if (!hasPauses) return;
            this.currentPauses = { ...workTime }.pause.map(p => ({
                row: [
                    { name: 'Dauer', value: getDuration(p.duration) },
                    { name: 'Start', value: format(parseISO(p.startAt), 'dd.MM.yy HH:mm') },
                    { name: 'Ende', value: format(parseISO(p.endAt), 'dd.MM.yy HH:mm') },
                    {
                        name: 'Akzeptiert',
                        props: {
                            keyName: 'accepted',
                            value: p.isAccepted,
                            uuid: p.uuid,
                            onCheck: checked => {
                                const pauseIndex = this.changedPauses.find(
                                    pause => pause.uuid === p.uuid,
                                );
                                const changedPause = { uuid: p.uuid, isActive: checked };
                                if (pauseIndex > -1) {
                                    this.changedPauses.splice(pauseIndex, 1, changedPause);
                                    return;
                                }
                                this.changedPauses.splice(0, 0, changedPause);
                            },
                        },
                        component: 'Checkbox',
                    },
                ],
            }));
            this.pauseModalIsActive = true;
        },
        async handleSavePauses() {
            if (!this.changedPauses.length) {
                this.changedPauses = [];
                this.handleModalClose();
                return;
            }
            const queryData = {
                pauses: this.changedPauses,
            };
            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours/pauses/set_checked_state`;
                const result = await axios.patch(url, queryData, {
                    withCredentials: true,
                });
                await this.handleQuery();
                this.changedPauses = [];
                this.handleModalClose();
            } catch (error) {
                this.$toasted.show('Etwas ist schief gelaufen, bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
            }
        },
        handleModalClose() {
            this.pauseModalIsActive = false;
            setTimeout(() => (this.currentPauses = []), 400);
        },
        handleDownload(type) {
            const year = this.selectedFrom.getYear() + 1900;
            const month = ('0' + (this.selectedFrom.getMonth() + 1)).slice(-2);
            const id = this.selectedEmployee.id;
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours/${year}/${month}/driver/${id}/${type}`;
            window.open(url, '_blank');
        },
        handleDateChange(dateRange) {
            this.selectedFrom = dateRange.from;
            this.selectedTo = dateRange.to;
        },
        handleDriverChange({ item }) {
            console.log('item', item);
            this.selectedEmployee = item;
        },
        async handleQuery() {
            this.isFetchingData = true;
            if (this.selectedEmployee) {
                await this.fetchEmployeeWorkTimeData();
            } else {
                await this.fetchAllWorkTimeData();
            }
        },
        async fetchEmployeeWorkTimeData() {
            try {
                const queryData = {
                    rangeStartAt: this.selectedFrom,
                    rangeEndAt: this.selectedTo,
                };
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours/daily/driver/${this.selectedEmployee.id}`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.employeeWorkTime = result.data;
            } catch (error) {
                this.$toasted.show('Etwas ist schief gelaufen, bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
            }
        },
        async fetchAllWorkTimeData() {
            try {
                const queryData = {
                    rangeStartAt: format(this.selectedFrom, 'yy-MM-dd'),
                    rangeEndAt: format(this.selectedTo, 'yy-MM-dd'),
                };
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/work_hours`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.$emit('onFinishLoading');
                this.workingTimes = result.data;
            } catch (error) {
                this.$toasted.show('Etwas ist schief gelaufen, bitte versuchen Sie es erneut', {
                    type: 'error',
                });
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.handleQuery();
        }, 300);
    },
};
</script>
<style lang="scss">
.WorkingTime {
    @extend %contentWrapper;
    @extend %page;

    .Spinner-Wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 80px 0;
    }
}
</style>
