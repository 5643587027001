var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "WorkingTime",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    attrs: {
      "title": "Übersicht der",
      "highlight": "Arbeitszeiten"
    }
  }), _c('Toolbar', [_c('div', [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "placeholder": "Fahrer",
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      }),
      "selected": _vm.selectedEmployee
    },
    on: {
      "onItemSelect": _vm.handleDriverChange
    }
  }), _c('Datepicker', {
    attrs: {
      "monthPicker": "",
      "startDate": _vm.selectedFrom
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")])], 1), _c('div', [_c('Download', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: !this.selectedEmployee ? 'Wählen Sie einen Fahrer aus' : '',
      expression: "!this.selectedEmployee ? 'Wählen Sie einen Fahrer aus' : ''"
    }],
    attrs: {
      "disabled": !this.selectedEmployee,
      "title": "Download PDF",
      "type": "pdf"
    },
    on: {
      "onDownload": _vm.handleDownload
    }
  })], 1)]), _vm.employeeWorkTime.length && _vm.selectedEmployee ? _c('employee-working-time-table', {
    attrs: {
      "workingTimes": this.employeeWorkTime
    },
    on: {
      "onClick": _vm.handleEmployeeRowClick
    }
  }) : _vm.workingTimes.length ? _c('working-time-table', {
    attrs: {
      "workingTimes": this.workingTimes
    },
    on: {
      "onClick": _vm.handleRowClick
    }
  }) : _vm.isFetchingData ? _c('div', {
    staticClass: "Spinner-Wrap"
  }, [_c('spinner')], 1) : _c('EmptyState'), _c('Modal', {
    attrs: {
      "title": "Markieren Sie die Pausen",
      "show": _vm.pauseModalIsActive,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSavePauses
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "size": 'medium',
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, _vm._l(_vm.currentPauses, function (pause, i) {
    return _c('row', {
      key: i,
      attrs: {
        "items": pause,
        "index": -1,
        "variant": "minimal"
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }