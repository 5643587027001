<template lang="html">
    <div class="Row-Wrap" :class="[{ 'is-empty': mWorkingTimes.length === 0 }]">
        <Row
            v-if="mWorkingTimes.length > 0"
            :parentHover="false"
            :items="{ row: header }"
            :key="-1"
            :index="-1"
            @onClickColumn="e => handleHeaderClick(e)"
            class="Row-Header"
        />
        <Row
            v-for="(workingTime, i) in mWorkingTimes"
            :items="workingTime"
            :key="i"
            :index="i"
            :hasBadge="hasPauses(workingTime)"
            @onHover="activeRow = i"
            @onLeave="activeRow = -1"
            @onClick="e => $emit('onClick', workingTime.driverNumber, e)"
        />
        <EmptyState v-if="mWorkingTimes.length === 0" />
    </div>
</template>

<script type="text/javascript">
import Row from '@/components/Row';
import { getDuration } from '../../../../lib/helper';
import EmptyState from '@/components/EmptyState.vue';

export default {
    name: 'WorkingTimesTable',
    components: {
        Row,
        EmptyState,
    },
    props: {
        workingTimes: {
            type: Array,
        },
    },
    data: () => {
        return {
            activeRow: -1,
            activeBar: -1,
            sortState: {
                column: 'Fahrer',
                ascending: true,
            },
        };
    },
    watch: {
        mWorkingTimes: {
            handler() {
                console.log('workingTimes changed', this.mWorkingTimes);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        header() {
            return [
                { name: 'Fahrer', value: null },
                { name: 'Fahrer Nummer', value: null },
                { name: 'Schichtzeit', value: null },
                { name: 'Arbeitszeit', value: null },
                { name: 'Pause', value: null },
            ].map(header => {
                let indicator = '';
                if (this.sortState.column === header.name) {
                    indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        mWorkingTimes() {
            return this.workingTimes.length > 0
                ? this.sortWorkingTimes([...this.formatWorkingTimes(this.workingTimes)])
                : [];
        },
    },
    methods: {
        handleRandomName() {
            const { fakerDE, fakerTR, fakerAR } = require('@faker-js/faker');

            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 2)];
        },
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();

            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        sortWorkingTimes(arr) {
            const { column, ascending } = this.sortState;
            return arr.sort((a, b) => {
                const aEntry = a.row.find(item => {
                    return item.name == column;
                });
                const bEntry = b.row.find(item => {
                    return item.name == column;
                });
                const aVal = aEntry?.rawValue || aEntry?.value;
                const bVal = bEntry?.rawValue || bEntry?.value;

                if (ascending) {
                    return aVal > bVal ? 1 : -1;
                }
                return aVal < bVal ? 1 : -1;
            });
        },
        hasPauses(w) {
            return w.pause?.length > 0;
        },

        formatWorkingTimes(workingTimes) {
            console.log('workingTimes', workingTimes);
            return workingTimes.map(workingTime => {
                return {
                    pause: workingTime.pause,
                    driverNumber: workingTime.driverNumber,
                    row: [
                        {
                            name: 'Fahrer',
                            value: this.handleRandomName() || '',
                            value: workingTime?.driverName || '',
                        },
                        { name: 'Fahrer Nummer', value: workingTime?.driverNumber || '' },
                        {
                            name: 'Schichtzeit',
                            value: getDuration(workingTime.shiftTime),
                            rawValue: workingTime.shiftTime,
                        },
                        {
                            name: 'Arbeitszeit',
                            value: getDuration(workingTime.workTime),
                            rawValue: workingTime.workTime,
                        },
                        {
                            name: 'Pause',
                            value: getDuration(workingTime.pauseTime),
                            rawValue: workingTime.pauseTime,
                        },
                    ],
                };
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.Row-Wrap {
    width: 100%;
    position: sticky;
    top: 0;

    &.is-empty {
        > * {
            min-width: 0;
        }
    }

    > * {
        min-width: 1000px;
    }

    @media (max-width: breakpoint(tabletPortrait)) {
        max-height: unset;
    }
}
</style>
