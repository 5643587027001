var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "stroke-width": "3",
      "stroke": "currentColor"
    }
  }, [_c('line', {
    attrs: {
      "x1": ".25",
      "x2": "8.75",
      "y1": ".25",
      "y2": "8.75"
    }
  }), _c('line', {
    attrs: {
      "x1": ".25",
      "x2": "8.75",
      "y1": ".25",
      "y2": "8.75",
      "transform": "rotate(-90 4.5 4.5)"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }