<template lang="html">
    <div class="Row-Wrap" :class="[{ 'is-empty': mWorkingTimes.length === 0 }]">
        <Row
            v-if="mWorkingTimes.length > 0"
            :parentHover="false"
            :items="{ row: header }"
            :key="-1"
            :index="-1"
            @onClickColumn="e => handleHeaderClick(e)"
            class="Row-Header"
        />
        <Row
            v-for="(workingTime, i) in mWorkingTimes"
            :items="workingTime"
            :key="i"
            :index="i"
            :hasBadge="hasPauses(workingTime)"
            @onHover="activeRow = i"
            @onLeave="activeRow = -1"
            @onClick="e => $emit('onClick', e)"
        />
        <EmptyState v-if="mWorkingTimes.length === 0" />
    </div>
</template>

<script type="text/javascript">
import { format } from 'date-fns';
import { getDuration } from '../../../../lib/helper';
import Row from '@/components/Row';
import EmptyState from '@/components/EmptyState.vue';

export default {
    name: 'WorkingTimesTable',
    components: {
        Row,
        EmptyState,
    },
    props: {
        workingTimes: {
            type: Array,
        },
    },
    data: () => {
        return {
            activeRow: -1,
            activeBar: -1,
            sortState: {
                column: 'Datum',
                ascending: true,
            },
        };
    },
    computed: {
        header() {
            return [
                { name: 'Datum', value: null },
                { name: 'Schichtzeit', value: null },
                { name: 'Arbeitszeit', value: null },
                { name: 'Pause', value: null },
                { name: 'Stunden 20-6 uhr', value: null },
                { name: 'Stunden 0-4 uhr', value: null },
                { name: 'Sonntag', value: null },
                { name: 'Feiertag 125%', value: null },
                { name: 'Feiertag 150%', value: null },
            ].map(header => {
                let indicator = '';
                if (this.sortState.column === header.name) {
                    indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        mWorkingTimes() {
            return this.workingTimes.length > 0
                ? this.sortWorkingTimes([...this.formatWorkingTimes(this.workingTimes)])
                : [];
        },
    },
    methods: {
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        sortWorkingTimes(arr) {
            const { column, ascending } = this.sortState;
            return arr.sort((a, b) => {
                const aEntry = a.row.find(item => {
                    return item.name == column;
                });
                const bEntry = b.row.find(item => {
                    return item.name == column;
                });
                const aVal = aEntry?.rawValue || aEntry?.value;
                const bVal = bEntry?.rawValue || bEntry?.value;
                if (ascending) {
                    return aVal > bVal ? 1 : -1;
                }
                return aVal < bVal ? 1 : -1;
            });
        },
        hasPauses(w) {
            return w.pause?.length > 0;
        },
        formatWorkingTimes(workingTimes) {
            return workingTimes.map(workingTime => {
                return {
                    pause: workingTime.pause,
                    row: [
                        {
                            name: 'Datum',
                            value: format(new Date(workingTime?.date), 'dd.MM.yyyy HH:mm').replace(
                                ' ',
                                '<br />',
                            ),
                            rawValue: new Date(workingTime?.date),
                        },
                        {
                            name: 'Schichtzeit',
                            value: workingTime.shiftTime
                                ? getDuration(workingTime.shiftTime)
                                : '--:--',
                            rawValue: workingTime.shiftTime,
                        },
                        {
                            name: 'Arbeitszeit',
                            value: workingTime.workTime
                                ? getDuration(workingTime.workTime)
                                : '--:--',
                            rawValue: workingTime.workTime,
                        },
                        {
                            name: 'Pause',
                            value: workingTime.pauseTime
                                ? getDuration(workingTime.pauseTime)
                                : '--:--',
                            rawValue: workingTime.pauseTime,
                        },
                        {
                            name: 'Stunden 20-6 uhr',
                            value: workingTime.eightToSix
                                ? getDuration(workingTime.eightToSix)
                                : '--:--',
                            rawValue: workingTime.eightToSix,
                        },
                        {
                            name: 'Stunden 0-4 uhr',
                            value: workingTime.zeroToFour
                                ? getDuration(workingTime.zeroToFour)
                                : '--:--',
                            rawValue: workingTime.zeroToFour,
                        },
                        {
                            name: 'Sonntag',
                            value: workingTime.sunday ? getDuration(workingTime.sunday) : '--:--',
                            rawValue: workingTime.sunday,
                        },
                        {
                            name: 'Feiertag 125%',
                            value: workingTime.holiday125
                                ? getDuration(workingTime.holiday125)
                                : '--:--',
                            rawValue: workingTime.holiday125,
                        },
                        {
                            name: 'Feiertag 150%',
                            value: workingTime.holiday150
                                ? getDuration(workingTime.holiday150)
                                : '--:--',
                            rawValue: workingTime.holiday150,
                        },
                    ],
                };
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.Row-Wrap {
    width: 100%;
    position: sticky;
    top: 0;

    &.is-empty {
        > * {
            min-width: 0;
        }
    }

    > * {
        min-width: 1000px;
    }
}
</style>
