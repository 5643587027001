var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Empty-State"
  }, [_c('span', {
    staticClass: "Empty-Text",
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }), _c('img', {
    staticClass: "Empty-Image",
    attrs: {
      "src": _vm.img
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }